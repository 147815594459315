import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OptionForm from '../components/OptionForm'; // Import OptionForm component
import { Helmet } from 'react-helmet';
import FadeLoader from 'react-spinners/FadeLoader';
import { msalInstance } from '../index';
import { loginRequest } from '../auth/AuthConfig';
import '../styles/ProcedureDetails.css';
import Header from '../components/header';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';

const ProcedureDetails: React.FC = () => {
  const { procedureName } = useParams<{ procedureName: string }>();
  const [selectedProcedure, setSelectedProcedure] = useState<string | null>(null);
  const [options, setOptions] = useState<any[]>([]);
  const [, setIsVerifying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedProcedureFromQuery = searchParams.get('name');

    if (selectedProcedureFromQuery) {
      setSelectedProcedure(selectedProcedureFromQuery);
    }

    const request = loginRequest; 
    const a = "Authorization";

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      const bearer = "Bearer " + tokenResponse.accessToken;
      const headers = new Headers();
      headers.append(a, bearer);
      const options = {
        method: "GET",
        headers: headers
      };
      const endURL = `/api/Procedure/GetProcedureParametersByNameAsync?name=${selectedProcedureFromQuery}`;
      const baseURL = process.env.REACT_APP_API_URL;

      const fetchProcedureParameters = async () => {
        await fetch(baseURL + endURL, options)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setOptions(data);
          } else {
            console.error('No parameters found in the response data.');
          }
        })
        .catch((error) => console.error('Failed to fetch options:', error))
        .finally(() => setIsLoading(false));
      };
      
      fetchProcedureParameters();
    });
  }, [procedureName, location.search]);

  const handleOptionChange = (optionId: string, value: any) => {
    setOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.id === optionId ? { ...option, value } : option
      )
    );
  };

  const verifyInfo = () => {
    setIsVerifying(true);
  };

  return (
    <MsalAuthenticationTemplate 
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <div className="full-height-form p-fluid">
        {selectedProcedure && (
          <Helmet>
            <title>Procedure: {selectedProcedure}</title>
          </Helmet>
        )}
        <Header />
        <h1 className='header'>{selectedProcedure}</h1>
        <h3>Enter the following required information:</h3>
        {isLoading ? (
          <div className='FadeLoaderProcedureDetails'>
            <FadeLoader color="#00342C"/>
          </div>
        ) : (
          <div className='form-group'>
            <OptionForm
              options={options}
              handleOptionChange={handleOptionChange}
              verifyInfo={verifyInfo}
              selectedProcedureName={selectedProcedure}
            />
          </div>
        )}
      </div>
    </MsalAuthenticationTemplate>
  );
};

export default ProcedureDetails;