import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';


export const NavbarData = [
    {
        title: 'Batch Scheduler',
        path: 'https://brave-forest-0c076fc10.4.azurestaticapps.net/',
        icon: <AiIcons.AiOutlineSchedule/>,
        cName: 'nav-text'
    },
    {
        title: 'Procedure Dashboard',
        path: '/Dashboard',
        icon: <IoIcons.IoIosPaper />,
        cName: 'nav-text'
    },
    {
        title: 'Procedure Details',
        path: '/ProcedureDetails',
        icon: <FaIcons.FaCartPlus />,
        cName: 'nav-text'
    },
]