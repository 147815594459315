import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import benesysLogo from '../../assets/images/benesys-logo.png';
import loginImage from '../../assets/images/login-img.svg';
import '../../styles/Login.css';

const Login: React.FC = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/Dashboard");  // Redirect to the dashboard
  };

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <img src={benesysLogo} alt='BeneSys Logo' className='benesys-logo'/>
      <div className="login-container mt-5">
        <h3 className='login-txt'>Sign in to the Benefit Driven Utility</h3>
        <form>
          <Button className='login-btn' onClick={handleLogin} label="Login"></Button>
        </form>
        <img src={loginImage} alt="Login Image" className="login-image" />
      </div>
    </>
  );
};

export default Login;