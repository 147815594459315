// ContextSwitcher.tsx
import React from 'react';
import { RadioButton } from 'primereact/radiobutton'; // Import PrimeReact components
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import DatePickerComponent from './DatePicker';
import { Button} from 'primereact/button';
import '../../styles/ProcedureDetails.css';
import calendar3 from '../../assets/images/calendar3.svg';
import { format } from 'date-fns';

interface ContextSwitcherProps {
  option: {
    id: string;
    parameterName: string;
    dataType: string;
  };
  inputValues: { [key: string]: string };
  setInputValues: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
  selectedDates: { [key: string]: Date | undefined };
  handleDateChange: (optionId: string, date: Date) => void;
  setShowDatePicker: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  showDatePicker: { [key: string]: boolean };
}

const ContextSwitcher: React.FC<ContextSwitcherProps> = ({
  option,
  inputValues,
  setInputValues,
  selectedDates,
  handleDateChange,
  setShowDatePicker,
  showDatePicker,
}) => {
  const handleDatePickerVisibility = () => {
    setShowDatePicker((prev) => ({
      ...prev,
      [option.id]: !prev[option.id],
    }));
  };


  return (
    <div className='form-group'>
      <label className='form-group label'>{option.parameterName}</label>
      {/* Replace form elements with PrimeReact components */}
      {option.parameterName === '@Comment' ? (
        <InputTextarea
          value={inputValues[option.id] || ''}
          placeholder='Please enter ticket number or why you are running this'
          maxLength={256}
          rows={5} // Example row size, adjust as needed
          className='comment-textarea form-control'
          onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
        />
      ) : option.parameterName === '@ClientNumber' ? (
        <div className='input-group'>
          <InputText
            type="text"
            value={inputValues[option.id] || ''}
            placeholder='BeneSys Client Number'
            className='.form-group input'
            onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
          />
        </div>
      ) : option.parameterName === '@Username' ? (
        <div className='input-group'>
          <InputText
            type="text"
            value={inputValues[option.id] || ''}
            placeholder='Your BeneSys Username'
            className='.form-group input'
            onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
          />
        </div>
      ) : option.dataType.toLowerCase() === 'date' || option.dataType.toLowerCase() === 'datetime' ? (
        <div className="input-group">
        <InputText
          type="datetime"
          value={selectedDates[option.id] ? format(selectedDates[option.id] as Date, 'MM-dd-yyyy') : ''}
          placeholder={option.dataType}
          className='form-group input'
          readOnly
          onClick={() => handleDatePickerVisibility()}
        />
        <Button onClick={handleDatePickerVisibility} className='cal-icon'>
          <img src={calendar3} alt="Calendar icon" />
        </Button>
        {showDatePicker[option.id] && (
          <DatePickerComponent
            onSelectDate={(date) => {
              handleDateChange(option.id, date);
              handleDatePickerVisibility();
            }}
          />
        )}
      </div>
      ) : option.dataType.toLowerCase() === 'bit' || option.dataType.toLowerCase() === 'boolean' ? (
        <div>
          <input
            type="radio"
            id={`true-${option.id}`}
            name={option.parameterName}
            value="1"
            onChange={() => setInputValues({ ...inputValues, [option.id]: '1' })}
            checked={inputValues[option.id] === '1'}
            className='radio-button-true'
          />
          <label className='radio-button-label-true' htmlFor={`true-${option.id}`}>True</label>

          <input
            type="radio"
            id={`false-${option.id}`}
            name={option.parameterName}
            value="0"
            onChange={() => setInputValues({ ...inputValues, [option.id]: '0' })}
            checked={inputValues[option.id] === '0'}
            className='radio-button-false'
          />
          <label className='radio-button-label-false' htmlFor={`false-${option.id}`}>False</label>
        </div>
      ) : (
        <div className='input-group'>
          <InputText
            type='text'
            value={inputValues[option.id] || ''}
            placeholder={option.dataType}
            className='form-group input'
            onChange={(e) => setInputValues({ ...inputValues, [option.id]: e.target.value })}
          />
        </div>
      )}
    </div>
  );
};

export default ContextSwitcher;