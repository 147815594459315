import React, { useState } from 'react';
import { ClassNames, DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import "../../styles/styles.css"; 

interface DatePickerProps {
  onSelectDate: (date: Date) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({ onSelectDate }) => {
  const [selectedDate, setSelectedDate] = useState<Date>();

  const classNames: ClassNames = {
    ...styles,
    head: 'custom-head'
  };

  return (
    <div className='calendar-pos'>
      <style>{`.custom-head { color: #00342C; }`}</style>
      <DayPicker
        mode="single"
        classNames={classNames}
        selected={selectedDate}
        captionLayout="dropdown-buttons" fromYear={1901} toYear={2080}
        onSelect={(date) => {
          if (date !== undefined) {
            setSelectedDate(date);
            onSelectDate(date);
          }
        }}
      />
    </div>
  );
};

export default DatePickerComponent;
