  import React, { useEffect, useState } from "react";
  import '../styles/header.css';
  import BDLogo from '../assets/images/BenefitDriven-logo.png';
  import { useMsal, useAccount } from '@azure/msal-react';

  const Header: React.FC = () => {
    const today = new Date();
    const date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    // get the user's name from Msal
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [name, setName] = useState("");

    useEffect(() => {
      if (account && account.name) {
        // set the account name to state but we need to swap the first and last name because the account.name is in the format of "LastName, FirstName"
        const splitName = account.name.split(',');
        const firstName = splitName[1].trim();
        const lastName = splitName[0].trim();
        const fullName = `${firstName} ${lastName}`;
        setName(fullName);
      } else {
          setName("");
      }
    }, [account]);

    let h1Color = '';
    let envSwitchColor = '';
    let textColor = '';
    switch (process.env.REACT_APP_SERVER_PIPELINE) {
      case 'DEV ENVIRONMENT':
        h1Color = '#00342C';
        envSwitchColor = '#00342C';
        textColor = 'white';
        break;
      case 'TEST ENVIRONMENT':
        h1Color = '#FCD12A';
        envSwitchColor = '#FCD12A';
        textColor = 'white';
        break;
      case 'PROD ENVIRONMENT':
        h1Color = 'white';
        envSwitchColor = 'white';
        textColor = 'black';
        break;
      default:
        h1Color = 'red';
        envSwitchColor = 'red';
        textColor = 'white';
        break;
    }

    return (
      <header className='banner'>
        <div className='headerFile'>
          <img className='BeneLogo' src={BDLogo} alt="Benesys Logo" />
          <div className='header-right'>
            <h1 className='env' style={{ backgroundColor: h1Color, color: textColor }}>{process.env.REACT_APP_SERVER_PIPELINE}</h1>
            <h2 className='welcome'>Welcome, {name}!</h2>
            <p className='date-header' style={{backgroundColor: h1Color, color: textColor}}>{date}</p>
            <p className="wrong-env">Wrong Environment?</p>
            {process.env.REACT_APP_SERVER_PIPELINE === 'DEV ENVIRONMENT' && (
              <a href={process.env.REACT_APP_TEST_ENV} rel="noopener noreferrer">
                <button className='EnvSwitch' style={{ backgroundColor: envSwitchColor, color: textColor }}>Switch To Test</button>
              </a>
            )}
            {process.env.REACT_APP_SERVER_PIPELINE === 'TEST ENVIRONMENT' && (
              <a href={process.env.REACT_APP_PROD_ENV} rel="noopener noreferrer">
                <button className='EnvSwitch' style={{ backgroundColor: envSwitchColor, color: textColor }}>Switch To Production</button>
              </a>
            )}
            {process.env.REACT_APP_SERVER_PIPELINE === 'PROD ENVIRONMENT' && (
              <a href={process.env.REACT_APP_TEST_ENV} rel="noopener noreferrer">
                <button className='EnvSwitch' style={{ backgroundColor: envSwitchColor, color: textColor }}>Switch To Test</button>
              </a>
            )}
          </div>
        </div>
      </header>
    );
  };

  export default Header;