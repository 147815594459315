import React, { useState } from 'react';
import ProcedureList from '../components/ProcedureList';
import { Helmet } from 'react-helmet';
import '../styles/dashboardStyles.css';
import Header from '../components/header';
import Navbar from '../components/utilities/NavBar';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from '../auth/AuthConfig';
import { useNavigate } from 'react-router-dom';

const Dashboard: React.FC = () => {
    const [, setSelectedProcedure] = useState<any | null>(null);
    const navigate = useNavigate();

    const selectProcedure = (procedure: any) => {
        procedure = procedure.replace(/\s/g, '');
        setSelectedProcedure(procedure);
        // Pass the selected procedure to ProcedureDetails
    };

    return (
        <MsalAuthenticationTemplate 
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
        >
            <div className="full-height-form p-fluid">
                <Helmet>
                    <title>Procedure Dashboard</title>
                </Helmet>
                <Header />
                <Navbar />
                <h1 className='Ph1'>Procedure Dashboard</h1>

                {/* Add SearchBox component */}
                <div className="options-list p-grid">
                    <div className="p-col-12 p-md-6">
                        <ProcedureList selectProcedure={selectProcedure} />
                    </div>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    );
};

export default Dashboard;