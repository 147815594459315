import React, { useEffect, useState } from 'react';
import '../styles/ProcedureList.css';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from 'react-router-dom';
import { msalInstance } from '../index';
import { loginRequest } from '../auth/AuthConfig';
import { FadeLoader } from 'react-spinners';

interface Props {
  selectProcedure: (procedure: string) => void;
}

const ProcedureList: React.FC<Props> = ({ selectProcedure }) => {
  const [procedures, setProcedures] = useState<string[]>([]);
  const [filteredProcedures, setFilteredProcedures] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true); // Add isLoading state
  const endURL = '/api/Procedure/GetAllProceduresAsync';
  const baseURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const request = loginRequest; 
    const a = "Authorization";

    msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      const bearer = "Bearer " + tokenResponse.accessToken;

    const headers = new Headers();
    headers.append(a,bearer);
    const options = {
            method: "GET",
            headers: headers
    };
    const fetchProcedureNames = async () => {
      await fetch(baseURL+endURL, options)
        .then((res) => res.json())
        .then((data) => {
          if (data != undefined) {
            const procedureNames = data.map(
              (procedure: { procedureName: string }) => procedure.procedureName
            );
            setProcedures(procedureNames);
            setFilteredProcedures(procedureNames);
          } else {
            console.error('No procedures found in the response data.');
          }
        })
        .catch((error) => console.error('Failed to fetch procedures:', error))
        .finally(() => setIsLoading(false)); // Set isLoading to false after fetching procedures
    };

    fetchProcedureNames();
  });
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = procedures.filter((procedure) =>
        procedure.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProcedures(filtered);
    } else {
      setFilteredProcedures(procedures);
    }
  }, [searchTerm, procedures]);

  const handleProcedureSelection = (procedure: string) => {
    selectProcedure(procedure);
    navigate({
      pathname: '/ProcedureDetails',
      search: '?name=' + procedure,
    });
  };

  return (
    <div className="card flex flex-wrap justify-content-center gap-3">
      <span className="p-input-icon-left custom-input-text">
        <i className="pi pi-search" />
        <InputText
          placeholder="Search for a procedure"
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </span>
      {isLoading ? ( // Display FadeLoader if isLoading is true\
        <div className='FadeLoader'>
          <FadeLoader color="#00342C"/>
        </div>
      ) : ( 
        <ul className="procedure-list">
          {filteredProcedures.map((procedure) => (
            <li
              className="procedure-list li"
              key={procedure}
              onClick={() => handleProcedureSelection(procedure)}
            >
              {procedure}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProcedureList;