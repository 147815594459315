import React from 'react';
import { Button } from 'primereact/button';
import '../styles/VerificationPopup.css';

interface Props {
  show: boolean;
  onHide: () => void;
  verificationResults: string[];
  onConfirm: () => void;
}

const VerificationPopup: React.FC<Props> = ({
  show,
  onHide,
  verificationResults,
  onConfirm,
}) => {
  // check if all verification results contain the string 'valid'
  const allValid = verificationResults.every((result) =>
    result.toLowerCase().includes(' valid')
  );

  const popupStyles: React.CSSProperties = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '20px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.7)',
    visibility: 'visible',
    opacity: '100',
    zIndex: 1000,
    display: show ? 'block' : 'none', // Show/hide the popup
  };
  
  const getResultStyle = (result: string): React.CSSProperties => {
    return {
      color: result.toLowerCase().includes('invalid') ? 'red' : 'inherit',
    };
  };
  
  return (
    <div style={popupStyles}>
      <h2>Confirm Your Input is Correct</h2>
      <div className="popup-content">
        {verificationResults.map((result, index) => (
          <p key={index} style={getResultStyle(result)}>{result}</p>
        ))}
      </div>
      <div>
        <Button className='button1' onClick={onHide}>
          Go Back
        </Button>
        {allValid && (
          <Button className='button2' onClick={onConfirm}>
            Confirm
          </Button>
        )}
      </div>
    </div>
  );
};

export default VerificationPopup;